


























































































































































































  import Vue from 'vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { appendSitePrefix } from '@/utils/routeUtils'

  const STATUS_UNVERIFIED = 0
  const STATUS_CORRECT = 1
  const STATUS_INCORRECT = 2
  const STATUS_ALL = 'all'

  const EXTERNAL_SYSTEM_STATUS_NO = 0
  const EXTERNAL_SYSTEM_STATUS_YES = 1
  const EXTERNAL_SYSTEM_STATUS_BOTH = 'both'

  const DEFAULT_ATTESTATION_STATUS = '' + STATUS_UNVERIFIED // as string :(
  const DEFAULT_HIDE_CANCELLED_ORDERS = false
  const DEFAULT_DIFF_FILTER = 'both'
  const DEFAULT_EON_INVOICE_STATUS = 'both'
  const DEFAULT_EXTERNAL_SYSTEM_STATUS = '' + EXTERNAL_SYSTEM_STATUS_BOTH
  let DEFAULT_MARGIN_FILTER = 0.0

  @Component
  export default class SupplierInvoiceAttestation extends Vue {
    loading = false
    saving = false
    attestationStatus: string = DEFAULT_ATTESTATION_STATUS
    pagination = {
      descending: true,
      page: 1,
      itemsPerPage: 50,
      sortBy: ['order_number']
    }

    pageMounted = false
    totalItems = null
    orderId = null
    modalData = {
      supplier_id: null,
      supplier_invoice_number: null,
      supplier_order_id: null,
      status: null as string | null,
      comment: null,
    }

    hideCancelledOrders = DEFAULT_HIDE_CANCELLED_ORDERS
    timer = null
    setStatusModal = false

    supplierId = null
    supplierModelSearchItems = []

    supplierOrderNumber = null
    documentNumber = null
    ids = []
    diffFilter: string = DEFAULT_DIFF_FILTER
    marginFilter: number = DEFAULT_MARGIN_FILTER
    externalSystemStatusFilter: string = DEFAULT_EXTERNAL_SYSTEM_STATUS

    items = []

    eonInvoiceStatus = DEFAULT_EON_INVOICE_STATUS

    get headers() {
      return [
        { text: this.$t('EON order'), value: 'order_number' },
        { text: this.$t('EON invoice'), value: 'eon_invoice_number' },
        { text: this.$t('Supplier invoice'), value: 'document_number' },
        { text: this.$t('Supplier order no.'), value: 'supplier_order_number' },
        { text: this.$t('Supplier'), value: 'supplier_name' },
        { text: this.$t('Date'), value: 'order_date' },
        { text: this.$t('Amount'), value: 'amount' },
        { text: this.$t('Diff'), value: 'amount_diff' },
        { text: this.$t('Status'), value: 'attestation_status' },
        { text: this.$t('Comment'), value: 'comment' },
        { text: this.$t('Actions'), value: 'actions' },
      ].filter((i) => !!i)
    }

    get statuses() {
      return [
        {
          value: '' + STATUS_UNVERIFIED,
          text: this.$t('c:show-attestation-status:Unverified'),
          textValue: 'undefined',
        },
        { value: '' + STATUS_CORRECT, text: this.$t('c:show-attestation-status:Correct'), textValue: 'correct' },
        { value: '' + STATUS_INCORRECT, text: this.$t('c:show-attestation-status:Incorrect'), textValue: 'incorrect' },
        { value: '' + STATUS_ALL, text: this.$t('c:show-attestation-status:All'), textValue: 'all' },
      ]
    }

    get eonInvoiceStatuses() {
      return [
        { value: 'both', text: this.$t('Both') },
        { value: 'yes', text: this.$t('Yes') },
        { value: 'no', text: this.$t('No') },
      ]
    }

    get isSearchById() {
      return this.orderId || this.documentNumber || this.supplierOrderNumber
    }

    mounted() {
      this.$axios.get('/v3/supplier-invoice/settings').then((response) => {
        DEFAULT_MARGIN_FILTER = response.data.data.maxDiff || 0.0

        this.attestationStatus = this.$route.params.attestation_status || '0'
        const queryString = this.$route.query

        this.hideCancelledOrders = queryString.hideCancelledOrders === '1'

        this.diffFilter = queryString.diffFilter ? (queryString.diffFilter as string) : 'both'

        if (queryString.orderId) {
          this.orderId = queryString.orderId
        }

        if (queryString.supplierOrderNumber) {
          this.supplierOrderNumber = queryString.supplierOrderNumber
        }

        if (queryString.supplierOrderNumber) {
          this.supplierOrderNumber = queryString.supplierOrderNumber
        }

        if (queryString.documentNumber) {
          this.documentNumber = queryString.documentNumber
        }

        if (queryString.supplierId) {
          this.supplierId = parseInt(queryString.supplierId.toString())
        }

        if (queryString.marginFilter) {
          this.marginFilter = parseFloat(queryString.marginFilter as string)
        } else {
          this.marginFilter = DEFAULT_MARGIN_FILTER
        }

        if (queryString.eonInvoiceStatus) {
          this.eonInvoiceStatus = '' + queryString.eonInvoiceStatus
        }

        this.pageMounted = true
        this.fetchItems()
      })

      this.$axios.get('/v3/supplier-invoice/get_initial_data').then((response) => {
        this.supplierModelSearchItems = response.data.data.suppliers
      })
    }

    canShowActions(actionStatus: string) {
      // No items selected
      if (this.ids.length === 0) {
        return false
      }

      // Decide distinct item statuses in selection
      const statuses = {}
      for (let a = 0; a < this.ids.length; a++) {
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].order_number === this.ids[a].order_number) {
            statuses[this.items[i].attestation_status] = 1
          }
        }
      }

      for (const status in statuses) {
        if (this.canShowAction({ attestation_status: status }, actionStatus)) {
          return true
        }
      }

      return false
    }

    canShowAction(item, actionStatus: string) {
      if (!item) {
        return false
      }
      if (!item.supplier_order_number) {
        return false
      }
      const itemStatus = parseInt(item.attestation_status)
      const itemExternalSystemStatus = parseInt(item.attestation_external_system_status)
      switch (actionStatus) {
        case 'comment':
          return true
        case 'external_system_status_yes':
          return itemExternalSystemStatus === EXTERNAL_SYSTEM_STATUS_NO
        case 'external_system_status_no':
          return itemExternalSystemStatus === EXTERNAL_SYSTEM_STATUS_YES
        case 'unverified':
          return itemStatus === STATUS_CORRECT || itemStatus === STATUS_INCORRECT
        case 'correct':
          return itemStatus === STATUS_UNVERIFIED
        case 'incorrect':
          return itemStatus === STATUS_UNVERIFIED
        default:
          console.error('Unknown status to canShowAction:', actionStatus)
      }
    }

    attest(status, items, cb) {
      this.$axios
        .post('/v3/supplier-invoice/attest', { status: status, items: items })
        .then((response) => response.data)
        .then(() => {
          cb()
        })
    }

    saveStatus(item, status) {
      const self = this
      const data = this.itemToBackend(item, status)
      this.saving = true

      this.attest(data.status, [data], function () {
        self.fetchItems()
      })
    }

    saveExternalSystemStatus(item, externalSystemStatus) {
      const data = this.itemToBackend(item, null, externalSystemStatus)
      this.$axios
        .post('/v3/supplier-invoice/external_system_status', data)
        .then((response) => response.data)
        .then(() => {
          this.fetchItems()
        })
    }

    saveComment(item) {
      const data = this.itemToBackend(item, null)
      this.$axios
        .post('/v3/supplier-invoice/comment', data)
        .then((response) => response.data)
        .then(() => {
          this.fetchItems()
          this.setStatusModal = false
        })
    }

    openModal(item) {
      this.modalData.supplier_id = item.supplier_id
      this.modalData.supplier_invoice_number = item.document_number
      this.modalData.supplier_order_id = item.supplier_order_number

      this.modalData.status = 'skipped'
      this.modalData.comment = item.comment
      this.setStatusModal = true
    }

    fetchItems() {
      if (!this.pageMounted) {
        return
      }
      this.loading = true

      let url = `/v3/supplier-invoice?sort=${this.pagination.sortBy}&desc=${this.pagination.descending}&page=${this.pagination.page}&attestation_status=${this.attestationStatus}`

      if (this.orderId) {
        url += `&order_id=${this.orderId}`
      }

      if (this.hideCancelledOrders) {
        url += '&exclude_cancelled_orders=1'
      }

      // filter/get by supplier
      if (this.supplierId) {
        url += `&supplier_id=${this.supplierId}`
      }

      if (this.supplierOrderNumber) {
        url += `&supplier_order_number=${this.supplierOrderNumber}`
      }

      if (this.documentNumber) {
        url += `&document_number=${this.documentNumber}`
      }

      if (this.marginFilter) {
        url += `&diff_margin=${this.marginFilter}`
      }

      if (this.diffFilter === 'diff') {
        url += '&diff_filter=diff'
      } else if (this.diffFilter === 'no_diff') {
        url += '&diff_filter=no_diff'
      }

      if (this.eonInvoiceStatus) {
        url += `&eon_invoice_status=${this.eonInvoiceStatus}`
      }

      if (this.externalSystemStatusFilter) {
        url += `&external_system_status=${this.externalSystemStatusFilter}`
      }

      this.$axios.get(url).then((response) => {
        this.totalItems = response.data.meta.total_items
        this.items = response.data.data ? response.data.data.map(this.parseItems) : []
        this.ids = []
        this.loading = false
        this.saving = false
        this.$vuetify.goTo(0, { easing: 'easeInOutQuad', duration: 150 })
      })
    }

    @Watch('pagination')
    onPaginationChange() {
      this.fetchItems()
    }

    parseItems(item) {
      const attestStatusList = [
        this.$t('c:show-attestation-status:Unverified'),
        this.$t('c:show-attestation-status:Correct'),
        this.$t('c:show-attestation-status:Incorrect'),
      ]
      item.attestation_status_text = attestStatusList[item.attestation_status]
      item.url = appendSitePrefix(`/${item.order_number}/order2/main`)
      /* eslint-disable-next-line */
			item.amount_diff = item.amount_diff // WHY?

      return item
    }

    itemToBackend(item, status, externalSystemStatus = null) {
      return {
        status: status,
        supplier_id: item.supplier_id,
        supplier_invoice_number: item.document_number || item.supplier_invoice_number,
        supplier_order_id: item.supplier_order_number || item.supplier_order_id,
        comment: item.comment,
        external_system_status: externalSystemStatus,
      }
    }

    // todo: order_number will not work as key, can have multiple order lines.... or is that order product id?
    saveMultipleStatusValues(status) {
      const self = this

      if (self.ids) {
        const jobs = []
        this.saving = true
        const items = []

        self.ids.forEach(function (id) {
          const item = self.items.find((i) => i.order_number === id)
          if (item) {
            const data = self.itemToBackend(item, status)
            items.push({
              supplier_id: item.supplier_id,
              supplier_invoice_number: item.document_number,
              supplier_order_id: item.supplier_order_number,
            })
          }
        })

        self.attest(status, items, function () {
          self.fetchItems()
        })
      }
    }

    getSupplierOrderNumberText(item) {
      if (item.supplier_order_number) {
        return item.supplier_order_number
      } else if (item.supplier_order_attempt_id) {
        return '(ordered without number)'
      } else {
        return '(not ordered yet)'
      }
    }

    getSupplierOrderNumberClass(item) {
      if (item.supplier_order_number) {
        return { 'supplier-order-status-ok': true }
      } else if (item.supplier_order_attempt_id) {
        return { 'supplier-order-status-no-number': true }
      } else {
        return { 'supplier-order-status-not-ordered': true }
      }
    }

    getStatusClass(item) {
      const statusClasses = ['unverified', 'correct', 'incorrect']
      const className = 'status-' + statusClasses[item.attestation_status]
      const classes = {}
      classes[className] = true
      return classes
    }

    getDiffClass(item) {
      if (item.amount_diff === null) {
        return { 'diff-missing': true }
      }
      const diff = this.marginFilter - Math.abs(item.amount_diff)
      if (diff > 0) {
        return { 'diff-ok': true }
      } else {
        return { 'diff-not-ok': true }
      }
    }

    getDiffText(item) {
      if (item.amount_diff === null) {
        return '?'
      }
      return item.amount_diff
    }

    resetSearch() {
      this.attestationStatus = DEFAULT_ATTESTATION_STATUS
      this.hideCancelledOrders = DEFAULT_HIDE_CANCELLED_ORDERS
      this.marginFilter = DEFAULT_MARGIN_FILTER
      this.diffFilter = DEFAULT_DIFF_FILTER
      this.eonInvoiceStatus = DEFAULT_EON_INVOICE_STATUS
      this.supplierOrderNumber = ''
      this.orderId = ''
      this.supplierId = ''
      this.documentNumber = ''
      this.gotoRoute()
    }

    gotoRoute() {
      this.pagination.page = 1

      if (this.isSearchById) {
        this.attestationStatus = 'all'
        this.diffFilter = 'both'
        this.hideCancelledOrders = false
        this.eonInvoiceStatus = 'both'
      }

      this.$router.push({
        name: 'Supplier/InvoiceAttestation',
        params: {
          attestation_status: this.attestationStatus,
        },
        query: {
          supplierOrderNumber: this.supplierOrderNumber,
          hideCancelledOrders: this.hideCancelledOrders ? '1' : '0',
          orderId: this.orderId,
          supplierId: this.supplierId,
          documentNumber: this.documentNumber,
          diffFilter: this.diffFilter,
          marginFilter: this.marginFilter.toString(),
          eonInvoiceStatus: this.eonInvoiceStatus,
        },
      })

      this.fetchItems()
    }
  }
